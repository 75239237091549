import React, {Component} from 'react';
import "../utils/classList.js";
import "./expandablePanel.css";
import images from '../../res/images.js';

const panelState = {
  COLLAPSED: 0,
  EXPANDING: 1,
  EXPANDED: 2,
  COLLAPSING: 3
}

class ExpandablePanel extends Component{
  constructor(props){
    super(props);
    this.state={
      expanded: false,
      panelState: panelState.COLLAPSED
    }

    this.expandPanel = this.expandPanel.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  expandPanel(){
    this.setState({expanded: !this.state.expanded});
    switch(this.state.panelState){
      case panelState.COLLAPSED:
        this.setState({panelState: panelState.EXPANDING});
        break;
      case panelState.EXPANDING:
        this.setState({panelState: panelState.COLLAPSING});
        break;
      case panelState.EXPANDED:
        this.setState({panelState: panelState.COLLAPSING});
        break;
      case panelState.COLLAPSING:
        this.setState({panelState: panelState.EXPANDING});
        break;
      default:
        break;
    }
    return null
  }

  onAnimationEnd(){
    console.log("animation ended")
    switch(this.state.panelState){
      case panelState.EXPANDING:
        this.setState({panelState: panelState.EXPANDED})
        break;
      case panelState.COLLAPSING:
        this.setState({panelState: panelState.COLLAPSED})
        break;
      default:
        break;
    }
  }

  render(){
    let className = "ex-panel_button"
    console.log("We be changin")
    console.log(this.state.panelState);
    switch(this.state.panelState){
      case panelState.COLLAPSED:
        className += " ex-panel_button-collapsed";
        break;
      case panelState.EXPANDING:
        className += " ex-panel_button-expanding";
        break;
      case panelState.EXPANDED:
        className += " ex-panel_button-expanded";
        break;
      case panelState.COLLAPSING:
        className += " ex-panel_button-collapsing";
        break;
      default:
        break;
    }

    return(
      <div
        style={this.props.style ? this.props.style : {width: "100%"}}
      >
        <div
          id="ex-panel-header"
          style={{
            width: "100%",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "align-left",
            textAlign: "left",
            alignItems: "flex-end",
            borderBottom: "solid 1px grey"
          }}
        >
        <div
          className="ex-panel_button-holder"
        >
          <button
            className={className}
            onClick={this.expandPanel}
            onAnimationEnd={this.onAnimationEnd}
          >

          </button>
        </div>
          <div
            className="ex-panel-title"
          >
              {this.props.title ? this.props.title : "Place Holder Title"}
          </div>
        </div>
        <div
          className="ex-panel-content"
          style={{
            width:"100%"
          }}
        >
          {this.state.expanded ? this.props.children : null}
        </div>
      </div>
    );
  }
}
 export default ExpandablePanel;
