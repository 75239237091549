import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Home from './pages/Home';
import Services from './pages/Services'
import Results from './pages/Results'
import ContactUs from './pages/ContactUs'


class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <div className="Nav-bar">
          <Link
            className="Nav-button"
            to="/"
            id="left-nav-button"
            >
                Home
            </Link>
            <Link
              className="Nav-button"
              to="/services/"
            >
              Services
            </Link>
            {/* <Link
              className="Nav-button"
              to='/results/'
            >
              Results
            </Link> */}
            <Link
              className="Nav-button"
              id="right-nav-button"
              to="/contact-us/"
            >
              Contact Us
            </Link>

          </div>
          <div
            className="Nav-bar-padder"
          >
          </div>
          <div
            className="Title"
          >
              Hestia Cleaning Service
            <span
              style={{
                fontSize: "8vw"
              }}
              >
              &nbsp; LLC
            </span>
          </div>
          <div className="Content">
            <Route path="/" exact component={Home}/>
            <Route path="/services/" component={Services}/>
            <Route path="/results/" component={Results}/>
            <Route path="/contact-us/" component={ContactUs}/>
          </div>
          <div className="Hestia-footer">
            Website design and implementation by Kevin Delamo
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
