import React, {Component} from 'react'
import "./contentCycler.css"

const itemState = {
  FADEIN: 0,
  DISPLAY: 1,
  FADEOUT: 2,
  FADED: 3,
}



class ContentCycler extends Component {

  constructor(props){
    super(props);
    this.state={
      currentItem: 0,
      itemState: itemState.FADEIN,
      position: {
        x: 0,
        y: 0
      }
    }
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
    this.item = React.createRef();
    this.container = React.createRef();
  }
  componentDidUpdate(){
    this.maxY = this.container.current.clientHeight;
    this.maxX = this.container.current.clientWidth;
    this.height = this.item.current.clientHeight;
    this.width = this.item.current.clientWidth;
    this.maxY = this.maxY - this.height;
    this.maxX = this.maxX -this.width;
    console.log("Boundaries");
    console.log(this.maxX);
    console.log(this.maxY);
    console.log(this.height);
    console.log(this.width);

  }

  onAnimationEnd(){

    let currentItem = this.state.currentItem

    switch(this.state.itemState){
      case itemState.FADED:
        this.setState({
          itemState: itemState.FADEIN,
          position: {
            x: Math.floor(Math.random() * (this.maxX)) ,
            y: Math.floor(Math.random() * (this.maxY))
          }
        });
        break;
      case itemState.FADEIN:
        this.setState({itemState: itemState.DISPLAY});
        break;
      case itemState.DISPLAY:
        this.setState({itemState: itemState.FADEOUT});
        break;
      case itemState.FADEOUT:
        let newCurrentItem = (currentItem + 1)%this.props.contentList.length;
        this.setState({
          itemState: itemState.FADED,
          currentItem: newCurrentItem,
        });
        break;
      default:
        break;
    }
  }

  render(){
    let className = "Content-cycler-item";
    console.log("The position to be rendered")
    console.log(this.state.position);
    switch(this.state.itemState){
      case itemState.FADED:
        className += " Content-cycler-item-faded";
        break;
      case itemState.FADEIN:
        className += " Content-cycler-item-fade-in";
        break;
      case itemState.DISPLAY:
        className = "Content-cycler-item";
        break;
      case itemState.FADEOUT:
        className += " Content-cycler-item-fade-out";
        break;
      default:
        break;
    }



    return(
      <div
        className={this.props.className}
      >
        <div
          className="Content-cycler"
          ref={this.container}
        >
          <div
            className={className}
            onAnimationEnd={this.onAnimationEnd}
            ref={this.item}
            style={{
              top: this.state.position.y,
              left:this.state.position.x
            }}
          >
            {this.props.contentList ? this.props.contentList[this.state.currentItem] : ""}
          </div>
        </div>
      </div>
    );
  }
}
export default ContentCycler;
