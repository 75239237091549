import React, {Component} from 'react'
import ExpandablePanel from '../../common/components/ExpandablePanel'
import strings from '../../res/strings';
import "./home.css"
import ContentCycler from '../../common/components/contentCycler'
class Home extends Component{
  constructor(props){
    super(props);
  }

  render(){

    const reviewHighlights = strings.home.reviews.map((review, key) => {
      return (
        <div
          style={{
            display: "flex",
            flexFlow: "column nowrap",
            fontFamily: "Times New Roman",
          }}
        >
          <div>
            {review.highlight}
          </div>
          <div
            style={{
              textAlign: "right"
            }}
          >
             - {review.client}
          </div>
        </div>
      )
    });

    return(
      <div>
        <ContentCycler
          contentList={reviewHighlights}
          className="Home_review-cycler"
          contentMaxWidth="40%"
        />
        <div
          className="Content-panel"
        >
        <div className="home_about-us">
        <h1>About Us</h1>

        <p>Hestia Cleaning Service LLC has been around since 2014 with the main goal
          of providing clients with an excellent cleaning service. We look to ensure
          that our clients are always happy and satisfied with our services. We
          are committed to always catering to our clients' needs at an afforfable
          rate without sacrificing quality.
          </p>
          <p>The company promises reliable, detail-oriented quality service every time.
          We work hard to give that guarantee to every single one of our clients.
          Hestia Cleaning Service LLC strives to provide the perfect combination
          of professionalism and quality with a personal touch.
          </p>
          <p> Our aim, along with an excellent cleaning service, is to earn the
          trust and loyalty of our clients through consistency and undeniably
          stellar results.  We make it a point to understand the wants and needs
          of our clients and comprehend how personal it is to clean our clients'
          properties.
          </p>
          <p> Our clients trust that their property is in good hands with Hestia
          Cleaning Service, LLC. Every single one of our employees are trustworthy
          and have been trained to provide the excellent service.
          </p>
          <p>
          Hestia Cleaning Service LLC is one of a kind with guaranteed professionalism,
          quality, and attention to detail. We understand it is a privilege to be
          in our clients' properties and treating them both with the utmost respect
          is the guiding principle for our work.
          </p>
          </div>
          <div>
          <h1>FAQs</h1>
          <list className="home_faq-questions">
          <li>Is Hestia Cleaning Service, LLC insured and bonded?</li>
            <ul className="home_faq-answers">
              <li>Yes, Hestia Cleaning Service, LLC is insured and bonded. We
              understand that it is a privilege to be in your home, and we are
              always careful. In the unlikely event that an object is damaged,
              please notify us within 24 hours.</li>
            </ul>
            <li>Is Hestia Cleaning Service, LLC a pet friendly cleaning service?</li>
              <ul className="home_faq-answers">
                <li>Yes! At Hestia Cleaning Service, LLC we LOVE pets. All of our
                employees are trained to correctly handle your furry family members.
                </li>
              </ul>
            <li>Can I trust Hestia Cleaning Service, LLC?</li>
                <ul className="home_faq-answers">
                  <li>Absolutely! All employees of Hestia Cleaning Service, LLC
                  are highly trained as well as insured and bonded.
                  </li>
                </ul>
          </list>
          </div>
          </div>
      </div>
    )
  }
}

export default Home;
