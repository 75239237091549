import React, {Component} from 'react';
import ExpandablePanel from '../common/components/ExpandablePanel.js'

import "./services.css"

class Services extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div>
        <div
          className="Content-panel"
        >
            <h1>Cleaning</h1>
            <p>
              Hestia offers incredible standard and deep cleaning services for multiple
              locations. We cater to the specific needs of our clients upon request.
            </p>
            <p
              style={{
                textAlign: "center"
              }}
            >
              *Click on our various provided services for a detailed outlook*
            </p>
            <div
              className="services_cleaning-service-table"
            >
              <div
                className="services_cleaning-service-block"
              >
                <ExpandablePanel
                  title="Residential"
                >
                <p>
                The list of our works, which we provide, includes all necessary
                procedures for creating the cleanliness, and keeping the residence in
                appropriate sanitary conditions! Our cleaning experts will do
                the cleaning indoors & ourdoors with an impeccable attention to detail.
                Our personnel have a responsible attitude towards their duties that guarantees an
                excellent result, and careful handling of your property!
                </p>
                  <list>
                    <li>Standard Cleaning:</li>
                      <ul>
                      <li>Dusting</li>
                      <li>Floor Cleaning - All Types!</li>
                      <li>Vacuum - If Accessible</li>
                      <li>Clean Countertops</li>
                      <li>Lanai - Floor & Furniture</li>
                      <li>Changing Out Garbage Bags</li>
                      <li>Soap Refill - If Provided</li>
                      <li>Replace Paper Products - If Provided </li>
                      <li>Kitchen Counters</li>
                      <li>Vanities</li>
                      <li>& More</li>
                      </ul>
                    <li>Move In/Out Cleaning:</li>
                      <ul>
                        <li>Includes Traditional Cleaning</li>
                        <li>Clean Inside Cabinets & Drawers</li>
                        <li>Clean Windows & Sills</li>
                        <li>Lanai - Sliding Doors, Track, & Frame</li>
                        <li>Baseboards & Molding</li>
                        <li>Ceiling Fans</li>
                        <li>& More</li>
                      </ul>
                  </list>
                </ExpandablePanel>
              </div>
              <div
              className="services_cleaning-service-block"
              >
                <ExpandablePanel
                  title="Rental/Vacation"
                >
                  <p>
                    Your vacationers want a clean getaway for their trip. We'd
                    be thrilled to make them, and you, happy.
                  </p>
                  <list>
                  <li>Standard Cleaning:</li>
                    <ul>
                      <li>Dusting</li>
                      <li>Floor Cleaning - All Types!</li>
                      <li>Vacuum - If Accessible</li>
                      <li>Clean Countertops</li>
                      <li>Lanai - Floor & Furniture</li>
                      <li>Changing Out Garbage Bags</li>
                      <li>Soap Refill - If Provided</li>
                      <li>Replace Paper Products - If Provided </li>
                      <li>Kitchen Counters</li>
                      <li>Vanities</li>
                      <li>& More</li>
                      </ul>
                  <li>Deep Clean</li>
                    <ul>
                      <li>Includes Traditional Cleaning</li>
                      <li>Clean Inside Cabinets & Drawers</li>
                      <li>Clean Windows & Sills</li>
                      <li>Lanai - Sliding Doors, Track, & Frame</li>
                      <li>Baseboards & Molding</li>
                      <li>Ceiling Fans</li>
                      <li>& More</li>
                    </ul>
                  </list>
                </ExpandablePanel>
              </div>
              <div
                className="services_cleaning-service-block"
              >
                <ExpandablePanel
                  title="Commercial"
                >
                  <p>
                    Businesses need to be kept clean to attract customers.
                    Call us and we'll make your business sparkle in those
                    customers' eyes.
                  </p>
                  <list>
                    <li>Standard Clean</li>
                      <ul>
                        <li>Dusting</li>
                        <li>Floor Cleaning - All Types!</li>
                        <li>Vacuum - If Accessible</li>
                        <li>Clean Countertops</li>
                        <li>Changing Out Garbage Bags</li>
                        <li>Soap Refill - If Provided</li>
                        <li>Replace Paper Products - If Provided </li>
                        <li>Vanities</li>
                        <li>& More</li>
                      </ul>
                    <li>Deep Clean</li>
                      <ul>
                        <li>Includes Traditional Cleaning</li>
                        <li>Clean Inside Cabinets & Drawers</li>
                        <li>Clean Windows & Sills</li>
                        <li>Baseboards & Molding</li>
                        <li>Ceiling Fans</li>
                        <li>& More</li>
                      </ul>
                  </list>
                </ExpandablePanel>
              </div>
              <div
                className="services_cleaning-service-block"
              >
                <ExpandablePanel
                  title="Construction Sites"
                >
                <p>
                Our goal in handling your cleanup project is to work efficiently
                and effectively, saving you time and money and meeting your deadlines.
                We understand that as a developer or builder, you have limited
                time and many projects to manage. At Hestia Cleaning we have the
                experience needed to handle your entire cleaning project. We handle
                all aspects of the cleaning project, including developing a plan,
                managing our cleaning team, and preparing everything needed to
                launch your project successfully. Tackling a major new development
                cleaning project isn’t a simple task that any maid service can
                complete. It requires experience in managing large projects, a
                team of cleaners that can operate efficiently as a unit, and
                industrial cleaning supplies that are suitable for large cleaning
                projects.
                </p>
                <list>
                <ul>
                  <li>Dusting</li>
                  <li>Floor Cleaning - All Types!</li>
                  <li>Vacuum - If Accessible</li>
                  <li>Clean Countertops</li>
                  <li>Lanai - Floor & Furniture</li>
                  <li>Changing Out Garbage Bags</li>
                  <li>Soap Refill - If Provided</li>
                  <li>Replace Paper Products - If Provided </li>
                  <li>Vanities</li>
                  <li>Clean Inside Cabinets & Drawers</li>
                  <li>Clean Windows & Sills</li>
                  <li>Lanai - Sliding Doors, Track, & Frame</li>
                  <li>Baseboards & Molding</li>
                  <li>Ceiling Fans</li>
                  <li>& More</li>
                </ul>
                </list>
                </ExpandablePanel>
              </div>
            </div>
            <h2>Alternate Services</h2>
            <div
              className="services_alternate-services-table"
            >
              <div
                className="services_alternate-service-block"
              >
                <ExpandablePanel
                  title="Event Waitressing"
                >
                  <list
                    className="services_service-details-list"
                  >
                    <li>Serve buffet food to event attendees</li>
                    <li>Serve and replenish drinks at table</li>
                    <li>Assist in after dinner clean up</li>
                  </list>
                </ExpandablePanel>
              </div>
              {/* <div
                className="services_alternate-service-block"
              >
                <ExpandablePanel
                  title="Dog Sitting"
                >
                  <list
                    className="services_service-details-list"
                  >
                    <li>Cater to the dogs established habits</li>
                      <ul>
                        <li>Feeding Schedule</li>
                        <li>Walking Schedule</li>
                        <li>Playtime</li>
                      </ul>
                    <li>We take care of your pet at our location</li>
                    <li>We use provided products for special needs</li>
                  </list>
                </ExpandablePanel>
              </div> */}
            </div>
            <h3>Locations Offered</h3>
            <p
              style={{
                textAlign: 'center'
              }}
            >
            Hestia is a South-West Florida based business that services the following
            locations:
            </p>
            <list
              style={{
                textAlign: "center"
              }}
            >
              <li>Bonita Springs</li>
              <li>Cape Coral</li>
              <li>Captiva</li>
              <li>Estero</li>
              <li>Fort Myers</li>
              <li>Lehigh Acres</li>
              <li>Naples</li>
              <li>Sanibel</li>
            </list>

        </div>
      </div>
    )
  }
}

export default Services;
