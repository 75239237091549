import React, {Component} from 'react';
import "./contactUs.css"
class ContactUs extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <div>
        <div
        className="Content-panel"
        >
        Hestia dedicates itself to flexibility in scheduling. Contact us so that
        we can get you on your way to a clean property.
          <div></div>
          <div
            className="contact-us_contact-info"
          >
          <div
            className="contact-us_contact-info-block"
          >
          Office Hours:
          <list>
          <li>Mon - Fri: 8:00 AM - 8:00 PM</li>
          <li>Sat: 9:00 AM - 2:00 PM</li>
          <li>Sun: Closed</li>
          </list>
          </div>
          <div
            className="contact-us_contact-info-block"
          >
          You can contact Hestia Cleaning Service via the following:
          <list>
            <li>hestiacleaningservice@gmail.com</li>
            <li>(239) 333-7985</li>
          </list>
          </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs;
