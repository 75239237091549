const strings = {
  home: {
    reviews:[
      {
        review: "FANTASTIC sums it up in one word. However, Hestia Cleaning "
                + "Services and it's own Diana Gomez deserve more than one word to describe "
                + "their wonderful services. Our home shines after every visit. They "
                + "clean ever inch of our space thoroughly. Just as important, they are "
                + "100% reliable and trustworthy and always accomodate our needs."
                + "Everyone is pleasant, personable, and a pleasure to have around.",
        highlight: "FANTASTIC sums it up in one word.",
        client: "Carol"
      },
      {
        review: "BEST CLEAN EVER! I am fortunate to have Diana and her girls "
                + "clean for me. My apartment sparkles! Diana always shows up "
                + "when she she will. Her price is fair and reasonable. She goes "
                + "above and beyond what is necessary for a good cleaning. She "
                + "is trustworthy and smart. Here's how I feel about having "
                + "Diana clean my apartment: LUCKY, LUCKY, LUCKY!",
        highlight:"Here's how I feel about having Diana clean my apartment: "
                  + "LUCKY, LUCKY, LUCKY!",
        client: "Sandy"
      },
      {
        review:"Excellent company and good prices. I recommend to any that wants "
                + "a good cleaning service",
        highlight: "Excellent company and good prices!",
        client: "Linda"
      },
      {
        review: "I hired this company 4 years ago to do a deep clean, and still "
                + "use their services every week. I'm very pleased with the "
                + "services. I recommend them to anyone who is looking for a "
                + "quality cleaning at the right price!",
        highlight: "I hired this company 4 years ago to do a deep clean, and still"
                  + " use their services every week!",
        client: "Barbara"
      },
      {
        review: "I hired this company to clean my home 4 years ago, and I'm super "
                + "pleased with the level of service they provide me. I made the "
                + "choice to get their services every 2 weeks. Anybody looking "
                + "for a cleaning company should give this company an opportunity.",
        highlight: "I'm super pleased with the level of service they provide me.",
        client: "Jan"
      },
      {
        review: "I am so grateful for Diana! She cleans with such attention to detail "
                + "and a great attitude. The house is a different place after "
                + "she comes.",
        highlight: "The house is a different place after Diana comes!",
        client:"Roxana & Robert"
      },
      {
        review: "Diana is efficient and thorough; she really pays attention to "
                + " the details. My house feels so much more comfortable every "
                + " time she comes.",
        highlight: "Diana really pays attention to the details.",
        client: "Ron"
      },
      {
        review: "What a great experience dealing with Hestia Cleaning. Quick to "
                + "respond, friendly, reliable, and does a terrific job. They have "
                + "become my regular service when I'm down in Florida!",
        highlight: "Quick to respond, friendly, reliable, and does a terrific job.",
        client: "Julie"
      },
      {
        review: "This was my first time booking with Hestia Cleaning, and I was "
                + "so so SO pleased with Diana. She was so sweet and personable, and "
                + "did an EXCELLENT job. I have two pets, and she made sure to "
                + "get rid of all the animal hair, even in tough nooks and crannies. "
                + "She also did a FANTASTIC job in my bathroom, which was pretty "
                + "terrifying at the start. Would DEFINITELY recommend this company!",
        highlight: "I have two pets, and she made sure to get rid of all animal hair, "
                  + "even in tough nooks and crannies!",
        client: "Michelle & David"
      },
      {
        review: "Our offices look great on Mondays! Thank you for the excellent job!",
        highlight: "Thank you for the excellent job!",
        client: "Michael"
      }

    ]
  }
}

export default strings;
