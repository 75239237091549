import React, {Component} from 'react';

class Results extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return (
      <div>
        <div
          className="Content-panel"
        >
          <div
            style={{
              textAlign: "center"
            }}
          >
            Under Construction - Coming Soon!
          </div>
        </div>
      </div>
    );
  }
}

export default Results;
